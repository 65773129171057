<template>
  <div class="dialog">
    <div class="dialog-header" v-if="dialogTitle">
      <b-row align-v="center">
        <b-col cols="9">
          <p class="dialog-title mb-0"> {{ dialogTitle }} </p>
        </b-col>
        <b-col cols="3">
          <img
            class="dialog-close-button"
            src="../../assets/images/close.png"
            alt="close-button"
            title="Close"
            v-if="disableClose === false"
            @click="$emit('close')"
          />
        </b-col>
      </b-row>
    </div>

    <div class="dialog-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props : {
      dialogTitle : {
        type     : String,
        required : false,
      },
      disableClose : {
        type    : Boolean,
        default : false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  p {
    font-family: 'ArialRoundedMTExtraBold' !important;
  }

  .dialog-title {
    text-transform: uppercase;
  }

  .dialog-content {
    p {
      color: $blue-text-stroke;
    }
  }

  .dialog-close-button {
    &:hover {
      cursor: pointer;
    }
  }

  @media only screen and (min-width: $xl2-min) {
    p {
      font-size: 1.3em !important;
      font-weight: normals;
    }
  }
  
</style>